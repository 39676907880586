import React, { useEffect, useState } from "react";
import { ViewMode, Gantt } from "gantt-task-react";
import ViewSwitcher from "./view-switcher";
import {
  getStartEndDateForProject,
  convertDateStringToDate,
  initTasks,
} from "../../helper/helper";
import "gantt-task-react/dist/index.css";
import Heading from "../../components/layout/Heading";
import "../../chart.css";
import { queryKeys } from "../../constants";
import usePostQuery from "../../hooks/usePostQuery";

const GanttChart = () => {
  const currentDate = new Date();
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState(initTasks());
  const [isChecked, setIsChecked] = useState(true);

  const { data, isLoading } = usePostQuery({
    queryKey: [queryKeys.TasksForCompany],
    url: "/task/for/company",
  });

  useEffect(() => {
    if (data?.message) {
      const convertDateStringToDate = (dateString) => {
        const [year, month, day] = dateString.split("-");
        return new Date(year, month - 1, day);
      };

      const projectMap = new Map();
      const lastTaskInProject = new Map(); // Map to track the last task ID for each project
      const tasks = [];

      data.message.forEach((task) => {
        const projectId = task.project_id;
        const projectName = task.project_name;

        if (!projectMap.has(projectId)) {
          const project = {
            start: convertDateStringToDate(task.start_date),
            end: convertDateStringToDate(task.end_date),
            name: `${projectName} Project`,
            id: projectId,
            progress: 25,
            type: "project",
            hideChildren: false,
            displayOrder: tasks.length + 1,
          };
          projectMap.set(projectId, project);
          tasks.push(project);
        }

        const lastTaskId = lastTaskInProject.get(projectId) || null;
        const taskObject = {
          start: convertDateStringToDate(task.start_date),
          end: convertDateStringToDate(task.end_date),
          name: task.task_name,
          id: task._id,
          progress: 45,
          type: "task",
          project: projectId,
          dependencies: lastTaskId ? [lastTaskId] : [],
          displayOrder: tasks.length + 1,
        };

        tasks.push(taskObject);
        lastTaskInProject.set(projectId, task._id); // Update the last task ID for the current project
      });

      setTasks(tasks);
    }
  }, [data]);

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };

  return (
    <div className="">
      <div className="mb-5 flex justify-between items-center px-12">
        <Heading label="Gantt Chart" />
        <ViewSwitcher
          onViewModeChange={(viewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
        />
      </div>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <div className="Wrapper">
          <Gantt
            tasks={tasks}
            viewMode={view}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={handleDblClick}
            onClick={handleClick}
            onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}
          />
        </div>
      )}
    </div>
  );
};

export default GanttChart;
