import React, { useEffect } from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";

const ClickHandler = ({
  setLabelPosition,
  setShowLabelForm,
  isAddingHotspot,
}) => {
  const { camera, scene, gl } = useThree();

  useEffect(() => {
    const handleCanvasClick = (event) => {
      if (isAddingHotspot) {
        const { clientX, clientY } = event;
        const { left, top, width, height } =
          gl.domElement.getBoundingClientRect();
        const x = ((clientX - left) / width) * 2 - 1;
        const y = -((clientY - top) / height) * 2 + 1;
        const mouse = new THREE.Vector2(x, y);

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObjects(scene.children, true);
        if (intersects.length > 0) {
          const intersect = intersects[0];
          const [x, y, z] = intersect.point.toArray();
          setLabelPosition([x, y, z]);
          setShowLabelForm(true);
        }
      }
    };

    gl.domElement.addEventListener("click", handleCanvasClick);

    return () => {
      gl.domElement.removeEventListener("click", handleCanvasClick);
    };
  }, [camera, scene, gl, isAddingHotspot, setLabelPosition, setShowLabelForm]);

  return null;
};

export default ClickHandler;
