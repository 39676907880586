import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";

const FloatingMenu = ({
  walls,
  selectedObject,
  setSelectedObject,
  objectColors,
  handleColorChange,
  newLabel,
  newDescription,
  setNewLabel,
  handleLabelSubmit,
  showLabelForm,
  setIsAddingHotspot,
  hotspots,
  setHotspots,
  handleEnvironmentChange,
  exposure,
  setExposure,
  shadowIntensity,
  setShadowIntensity,
  shadowSoftness,
  setShadowSoftness,
  metallicFactor,
  setMetallicFactor,
  roughnessFactor,
  setRoughnessFactor,
  selectedTab,
  setSelectedTab,
}) => {
  const [isHotspotListOpen, setIsHotspotListOpen] = useState(true);
  const [isMetallicRoughnessOpen, setIsMetallicRoughnessOpen] = useState(false);
  //   const [selectedTab, setSelectedTab] = useState("3D"); // New state for switching tabs

  const [description, setDescription] = useState("");
  const [selectedBuilder, setSelectedBuilder] = useState("");

  const handleDeleteHotspot = (index) => {
    setHotspots(hotspots.filter((_, i) => i !== index));
  };

  return (
    <div className="floating-menu bg-white pt-4 pl-4 pr-4 rounded shadow-md absolute top-4 right-4 z-10 w-72 h-[95%] overflow-y-scroll">
      {/* Tabs for switching between 3D and 2D models */}
      <div className="flex mb-4">
        <button
          className={`p-2 flex-1 ${
            selectedTab === "3D" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => setSelectedTab("3D")}
        >
          3D Model
        </button>
        <button
          className={`p-2 flex-1 ${
            selectedTab === "2D" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
          onClick={() => setSelectedTab("2D")}
        >
          2D Model
        </button>
      </div>

      {selectedTab === "3D" ? (
        <div className="">
          <h2 className="text-lg font-bold mb-2">3D Object Controls</h2>
          <div className="mb-4">
            <label className="block mb-1">Select Object</label>
            <select
              value={selectedObject || ""}
              onChange={(e) => setSelectedObject(e.target.value)}
              className="p-2 border rounded w-full"
            >
              <option value="" disabled>
                Select an object
              </option>
              {walls.map((wall, index) => (
                <option key={index} value={wall.name}>
                  {wall.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block mb-1">Change Color</label>
            <input
              type="color"
              value={
                selectedObject
                  ? objectColors[selectedObject] || "#ffffff"
                  : "#ffffff"
              }
              onChange={handleColorChange}
              className="w-full h-10 p-1 border rounded"
              disabled={!selectedObject}
            />
          </div>

          {/* Collapsible Metallic Roughness Section */}
          <div className="mb-4">
            <div
              className="cursor-pointer bg-gray-200 p-2 rounded flex justify-between items-center"
              onClick={() =>
                setIsMetallicRoughnessOpen(!isMetallicRoughnessOpen)
              }
            >
              <h2 className="text-lg font-bold">Adjust Scan</h2>
              <span>{isMetallicRoughnessOpen ? "▲" : "▼"}</span>
            </div>
            {isMetallicRoughnessOpen && (
              <div className="p-2 border rounded mt-2">
                <div className="mb-4">
                  <label className="block mb-1">Exposure</label>
                  <input
                    type="range"
                    min="0"
                    max="3"
                    step="0.01"
                    value={exposure}
                    onChange={(e) => setExposure(parseFloat(e.target.value))}
                    className="w-full"
                  />
                  <span>{exposure}</span>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Shadow Intensity</label>
                  <input
                    type="range"
                    min="0"
                    max="5"
                    step="0.01"
                    value={shadowIntensity}
                    onChange={(e) =>
                      setShadowIntensity(parseFloat(e.target.value))
                    }
                    className="w-full"
                  />
                  <span>{shadowIntensity}</span>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Shadow Softness</label>
                  <input
                    type="range"
                    min="0"
                    max="5"
                    step="0.01"
                    value={shadowSoftness}
                    onChange={(e) =>
                      setShadowSoftness(parseFloat(e.target.value))
                    }
                    className="w-full"
                  />
                  <span>{shadowSoftness}</span>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Metallic Factor</label>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={metallicFactor}
                    onChange={(e) =>
                      setMetallicFactor(parseFloat(e.target.value))
                    }
                    className="w-full"
                  />
                  <span>{metallicFactor}</span>
                </div>
                <div className="mb-4">
                  <label className="block mb-1">Roughness Factor</label>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={roughnessFactor}
                    onChange={(e) =>
                      setRoughnessFactor(parseFloat(e.target.value))
                    }
                    className="w-full"
                  />
                  <span>{roughnessFactor}</span>
                </div>
              </div>
            )}
          </div>

          <h2 className="text-lg font-bold mb-2">Hotspot Controls</h2>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
            onClick={() => setIsAddingHotspot(true)}
          >
            Add Annotation / Task
          </button>
          <div className="mb-4">
            <div
              className="cursor-pointer bg-gray-200 p-2 rounded flex justify-between items-center"
              onClick={() => setIsHotspotListOpen(!isHotspotListOpen)}
            >
              <h2 className="text-lg font-bold">Annotations & Tasks</h2>
              <span>{isHotspotListOpen ? "▲" : "▼"}</span>
            </div>

            {isHotspotListOpen && (
              <ul className="list-disc list-inside">
                {hotspots.map((hotspot, index) => (
                  <li key={index} className="p-2">
                    {hotspot.label}
                    <button
                      className="bg-transparent border-none text-red-500 px-2 py-1 rounded ml-4"
                      onClick={() => handleDeleteHotspot(index)}
                    >
                      <AiFillDelete />
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {showLabelForm && (
            <form onSubmit={handleLabelSubmit} className="mb-4">
              <label className="block mb-1">Annotation/Task Label</label>
              <input
                type="text"
                value={newLabel}
                onChange={(e) => setNewLabel(e.target.value)}
                className="p-2 border rounded w-full"
              />

              <label className="block mt-4 mb-1">Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="p-2 border rounded w-full"
              ></textarea>

              <label className="block mt-4 mb-1">Select Builder</label>
              <select
                value={selectedBuilder}
                onChange={(e) => setSelectedBuilder(e.target.value)}
                className="p-2 border rounded w-full"
              >
                <option value="builder1">Builder 1</option>
                <option value="builder2">Builder 2</option>
                <option value="builder3">Builder 3</option>
              </select>

              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded mt-4"
              >
                Add Label
              </button>
            </form>
          )}
        </div>
      ) : (
        <>
          <h2 className="text-lg font-bold mb-2">2D Object Controls</h2>
          {/* Add 2D-specific controls here */}
        </>
      )}
    </div>
  );
};

export default FloatingMenu;
