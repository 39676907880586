import { useEffect, useState } from "react";
import "@bitnoi.se/react-scheduler/dist/style.css";
import Heading from "../../components/layout/Heading";
import { Scheduler } from "@bitnoi.se/react-scheduler";
import './schedule.css';
import usePostQuery from "../../hooks/usePostQuery";
import { queryKeys } from "../../constants";

const Schedule = () => {
  const [filterButtonState, setFilterButtonState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);

  const { data } = usePostQuery({
    queryKey: [queryKeys.TasksForCompany],
    url: "/task/for/company",
  });

  useEffect(() => {
    if (data?.message) {
      const convertDateStringToDate = (dateString) => {
        const [year, month, day] = dateString.split("-");
        return new Date(year, month - 1, day);
      };

      const builderMap = new Map();

      data.message.forEach((task) => {
        task.task_builder.forEach((builder) => {
          if (!builderMap.has(builder._id.$oid)) {
            builderMap.set(builder._id.$oid, {
              id: builder._id.$oid,
              label: {
                icon: builder.profile,
                title: builder.name,
                subtitle: builder.role,
              },
              data: [],
            });
          }

          builderMap.get(builder._id.$oid).data.push({
            id: task._id.$oid,
            startDate: convertDateStringToDate(task.start_date),
            endDate: convertDateStringToDate(task.end_date),
            occupancy: Math.floor(Math.random() * 10000), // Placeholder for occupancy
            title: task.task_name,
            subtitle: task.task_description,
            description: task.task_description,
            bgColor: "rgb(254,165,177)", // Placeholder color
          });
        });
      });

      const formattedTasks = Array.from(builderMap.values());
      setTasks(formattedTasks);
    }
  }, [data]);

  useEffect(() => {
    setIsLoading(true);

    // fetching data

    setIsLoading(false);
  }, []);

  return (
    <div className="px-12">
      <div className="mb-5 flex justify-between items-center px-12">
        <Heading label="Scheduler" />
      </div>
      <div className="flex flex-wrap gap-5 bg-white p-5 justify-center">
        <section className="scheduler-container">
          <Scheduler
            data={tasks}
            isLoading={isLoading}
            onRangeChange={(newRange) => console.log(newRange)}
            onTileClick={(clickedResource) => console.log(clickedResource)}
            onItemClick={(item) => console.log(item)}
            onFilterData={() => {
              // Some filtering logic...
              setFilterButtonState(1);
            }}
            onClearFilterData={() => {
              // Some clearing filters logic...
              setFilterButtonState(0);
            }}
            config={{
              zoom: 10,
              filterButtonState,
            }}
          />
        </section>
      </div>
    </div>
  );
};

export default Schedule;
