import { useState } from "react";
import Heading from "../../components/layout/Heading";
import { Icon } from "@iconify/react/dist/iconify.js";
import useConfirm from "../../hooks/useConfirm";
import Table from "../../components/shared/Table";
import { Link } from "react-router-dom";
import { formatDistanceToNowStrict } from "date-fns";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import { queryKeys } from "../../constants";
import usePostQuery from "../../hooks/usePostQuery";
import DetailsModal from "../../components/shared/DetailsModal";
import BudgetDetails from "./budgetDetails";

const BudgetingPage = () => {
  const {
    ConfirmationDialog,
    confirm,
    setIsOpen: setOpenConfirm,
  } = useConfirm();
  const { formatCurrency } = useFormatCurrency();
  const [budget, setBudget] = useState(null);

  const handleDelete = async (builder) => {
    const isConfirm = await confirm({
      title: "Are you sure?",
      message: `Are you sure you want to delete "${builder?.f_name} ${builder?.l_name}"`,
    });
  };

  const { data, isLoading } = usePostQuery({
    queryKey: [queryKeys.Budgets],
    url: "/budget/all",
  });

  // console.log(data.message);
  const budgets = data?.message ?? [];

  const columns = [
    {
      id: "SN",
      accessorKey: "",
      header: "SN",
      cell: (info) => <span>{info.row.index + 1}</span>,
    },
    {
      accessorKey: "budget_name",
      header: "Name",
    },
    {
      header: "Client",
      id: "Client",
      accessorFn: (row) => {
        //console.log("row:::", row);
        return row?.client_name;
      },
    },
    {
      header: "Budget",
      id: "Budget",
      //accessorKey: "total",
      accessorFn: (row) => {
        return formatCurrency(row?.total);
      },
    },
    {
      header: "Actual",
      id: "Actual",
      // accessorKey: "actual",
      accessorFn: (row) => {
        return formatCurrency(row?.total_actual);
      },
    },
    {
      header: "Variance",
      id: "Variance",
      accessorFn: (row) => {
        return formatCurrency(row?.total_variance);
      },
      // accessorKey: "variance",
      //cell: (cell) => formatCurrency(cell.getValue()),
    },
    {
      header: "Actions",
      cell: (props) => (
        <span className="flex items-center gap-5">
          {/* <Link to={`/payment/budget/${props.row.original._id}`}> */}
          <button
            onClick={() => {
              console.log("props.row.original:::", props.row.original);

              setBudget(props.row.original);
            }}
            className="text-xs border border-primary text-primary py-1 px-3 rounded-md"
          >
            View Detail
          </button>
          {/* </Link> */}

          <Link to={`/payment/budget/${props.row.original._id}/edit`}>
            <Icon icon="iconamoon:edit-light" className="h-5 w-5" />
          </Link>
          <button onClick={() => handleDelete(props.row.original)}>
            <Icon
              icon="fluent:delete-28-regular"
              className="h-5 w-5 text-red-500"
            />
          </button>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="px-20 cursor-pointer">
        {/* <Spinner isSubmitting={isLoading} /> */}

        <div className="flex justify-between items-center mt-5">
          <Heading label="Project Budgets" />
          <Link
            to="/payment/budget/add-budget"
            className="rounded-lg py-3 px-5 bg-primary"
          >
            <div className="text-white font-semibold text-md">
              Start A New Budget
            </div>
          </Link>
        </div>
      </div>

      <div className="px-12 mt-5 rounded-lg">
        <Table data={budgets} columns={columns} isLoading={false} />
      </div>

      <DetailsModal
        openDetails={Boolean(budget)}
        start
        heading={`${budget?.client_name}'s ${budget?.budget_name}`}
        closeDetails={() => setBudget(null)}
      >
        <BudgetDetails setEstimate={setBudget} budget={budget} />
      </DetailsModal>

      {ConfirmationDialog()}
    </>
  );
};

export default BudgetingPage;
