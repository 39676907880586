import React, { useState } from "react";
import { Canvas } from "@react-three/fiber";
import Hotspot from "./hotspot";
import Model from "./model";
import FloorPlan from "./floorplan";
import FloatingMenu from "./floatingmenu";
import ClickHandler from "./ClickHandler";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import * as THREE from "three";
import { useGetQuery } from "../../../hooks/useGetQuery";
import { queryKeys } from "../../../constants";
import { useParams } from "react-router-dom";

function ThreeDProjects() {
  const [hotspots, setHotspots] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [objectColors, setObjectColors] = useState({});
  const [walls, setWalls] = useState([]);
  const [showLabelForm, setShowLabelForm] = useState(false);
  const [newLabel, setNewLabel] = useState("");
  const [labelPosition, setLabelPosition] = useState([0, 0, 0]);
  const [isAddingHotspot, setIsAddingHotspot] = useState(false);
  const [environment, setEnvironment] = useState(null);
  const [exposure, setExposure] = useState(1);
  const [shadowIntensity, setShadowIntensity] = useState(1);
  const [shadowSoftness, setShadowSoftness] = useState(1);
  const [metallicFactor, setMetallicFactor] = useState(0.5);
  const [roughnessFactor, setRoughnessFactor] = useState(0.5);
  const [selectedTab, setSelectedTab] = useState("3D");
  const [model, setModel] = useState(null);
  const { arProjectId } = useParams();

  const { data, isLoading } = useGetQuery({
    queryKey: [queryKeys.Projects, arProjectId],
    url: `/project/${arProjectId}`,
  });

  const project = data?.message ?? {};

  const handleColorChange = (event) => {
    if (selectedObject) {
      const newColor = event.target.value;
      setObjectColors({
        ...objectColors,
        [selectedObject]: newColor,
      });

      const wall = walls.find((wall) => wall.name === selectedObject);
      if (wall) {
        wall.material.color.set(newColor);
      }
    }
  };

  const handleLabelSubmit = (e) => {
    e.preventDefault();
    setHotspots([...hotspots, { position: labelPosition, label: newLabel }]);
    setNewLabel("");
    setShowLabelForm(false);
    setIsAddingHotspot(false);
  };

  const handleEnvironmentChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      const loader = new RGBELoader();
      loader.load(url, (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        setEnvironment(texture);
      });
    }
  };

  return (
    <div className="relative w-full h-[calc(100vh_-_100px)] bg-black/50">
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full">
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <Canvas
            style={{
              background: "#e5e5e5",
              cursor: isAddingHotspot ? "crosshair" : "default",
            }}
          >
            {selectedTab === "3D" ? (
              <>
                <ambientLight intensity={exposure} />
                <pointLight
                  position={[10, 10, 10]}
                  intensity={shadowIntensity}
                  shadow-mapSize-width={1024}
                  shadow-mapSize-height={1024}
                  shadow-radius={shadowSoftness}
                />
                <Model
                  fileUrl={
                    project?.project_images?.length > 0
                      ? project?.project_images[0]
                      : ""
                  }
                  setWalls={setWalls}
                  setModel={setModel}
                  environment={environment}
                  metallicFactor={metallicFactor}
                  roughnessFactor={roughnessFactor}
                />
                {hotspots.map((hotspot, index) => (
                  <Hotspot
                    key={index}
                    position={hotspot.position}
                    label={hotspot.label}
                  />
                ))}
                <ClickHandler
                  setLabelPosition={setLabelPosition}
                  setShowLabelForm={setShowLabelForm}
                  isAddingHotspot={isAddingHotspot}
                />
              </>
            ) : (
              <FloorPlan model={model} />
            )}
          </Canvas>
          <FloatingMenu
            walls={walls}
            selectedObject={selectedObject}
            setSelectedObject={setSelectedObject}
            objectColors={objectColors}
            handleColorChange={handleColorChange}
            newLabel={newLabel}
            setNewLabel={setNewLabel}
            handleLabelSubmit={handleLabelSubmit}
            showLabelForm={showLabelForm}
            setIsAddingHotspot={setIsAddingHotspot}
            hotspots={hotspots}
            setHotspots={setHotspots}
            handleEnvironmentChange={handleEnvironmentChange}
            exposure={exposure}
            setExposure={setExposure}
            shadowIntensity={shadowIntensity}
            setShadowIntensity={setShadowIntensity}
            shadowSoftness={shadowSoftness}
            setShadowSoftness={setShadowSoftness}
            metallicFactor={metallicFactor}
            setMetallicFactor={setMetallicFactor}
            roughnessFactor={roughnessFactor}
            setRoughnessFactor={setRoughnessFactor}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </>
      )}
    </div>
  );
}

export default ThreeDProjects;
