import React, { useState } from "react";

const FileUploadForm = ({
  profileImage,
  setProfileImage,
  coi,
  setCoi,
  contractorLicense,
  setContractorLicense,
  subcontractorAgreement,
  setSubcontractorAgreement,
}) => {
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [profileImageFileName, setProfileImageFileName] = useState("");
  const [coiPreview, setCoiPreview] = useState(null);
  const [contractorLicensePreview, setContractorLicensePreview] =
    useState(null);
  const [subcontractorAgreementPreview, setSubcontractorAgreementPreview] =
    useState(null);
  const [coiFileName, setCoiFileName] = useState("");
  const [contractorLicenseFileName, setContractorLicenseFileName] =
    useState("");
  const [subcontractorAgreementFileName, setSubcontractorAgreementFileName] =
    useState("");

  const handleFileChange = (e, setFile, setPreview, setFileName) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setPreview(URL.createObjectURL(file));
      setFileName(file.name); // Set the file name
    }
  };

  return (
    <div className="grid grid-cols-4 gap-4">
      {/* Profile Image */}
      <div className="flex-1 mt-4">
        <label className="text-blue-900 text-md font-semibold leading-loose px-5">
          Profile picture <span className="text-red-700 text-[15px]">*</span>
        </label>
        <div className="flex flex-col items-center gap-5 mt-2 md:flex-row">
          {profileImagePreview ? (
            <>
              <div className="h-40 w-full md:w-40 flex justify-center border-2 shrink-0 border-orange-300 border-dashed rounded-lg overflow-hidden">
                <img
                  src={profileImagePreview}
                  alt="Profile Preview"
                  className="w-60 h-40 object-cover"
                />
              </div>
              <input
                id="photoImage"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setProfileImage,
                    setProfileImagePreview,
                    setProfileImageFileName
                  )
                }
                accept="image/png, image/jpeg, image/jpg"
              />
            </>
          ) : (
            <label
              htmlFor="photoImage"
              className="flex-1 w-full block h-40 border-2 border-primary bg-[#F4F6FB] border-dashed rounded-lg cursor-pointer p-2"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 mb-3 mt-[-20px] text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <div className="text-center">
                  <span className="text-blue-900 text-[12px] font-bold leading-loose">
                    Drop your photo, or
                  </span>
                  <span className="text-primary text-[15px] font-bold leading-loose ml-2">
                    browse
                  </span>
                </div>
                <p className="text-xs text-center text-gray-500 dark:text-gray-400 mt-1">
                  PNG, JPG and GIF files are allowed
                </p>
              </div>
              <input
                id="photoImage"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setProfileImage,
                    setProfileImagePreview,
                    setProfileImageFileName
                  )
                }
                accept="image/png, image/jpeg, image/jpg"
              />
            </label>
          )}
        </div>
        {profileImagePreview && (
          <p
            onClick={() => {
              const input = document.getElementById("photoImage");
              if (input) {
                input.click();
              } else {
                console.error("Profile image input not found.");
              }
            }}
            className="cursor-pointer text-primary text-[15px] font-bold leading-loose mt-2"
          >
            Change Photo
          </p>
        )}
      </div>

      {/* COI */}
      <div className="flex-1 mt-4">
        <label className="text-blue-900 text-md font-semibold leading-loose">
          Cert. Of Liability Ins
        </label>
        <div className="flex flex-col items-center gap-5 mt-2 md:flex-row">
          {coiPreview ? (
            <>
              <div className="h-40 w-full md:w-40 flex justify-center border-2 shrink-0 border-orange-300 border-dashed rounded-lg overflow-hidden">
                <div className="flex items-center">
                  <span className="ml-1 text-gray-600 text-center">
                    {coiFileName}
                  </span>
                </div>
              </div>

              <input
                id="coi"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(e, setCoi, setCoiPreview, setCoiFileName)
                }
                accept=".pdf, .docx"
              />
            </>
          ) : (
            <label
              htmlFor="coi"
              className="flex-1 w-full block h-40 border-2 border-primary bg-[#F4F6FB] border-dashed rounded-lg cursor-pointer p-2"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 mb-3 mt-[-20px] text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <div className="text-center">
                  <span className="text-blue-900 text-[12px] font-bold leading-loose">
                    Drop your COI here, or
                  </span>
                  <span className="text-primary text-[15px] font-bold leading-loose ml-2">
                    browse
                  </span>
                </div>
                <p className="text-xs text-center text-gray-500 dark:text-gray-400 mt-1">
                  PDF and docx files are allowed
                </p>
              </div>
              <input
                id="coi"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(e, setCoi, setCoiPreview, setCoiFileName)
                }
                accept=".pdf, .docx"
              />
            </label>
          )}
        </div>
        {coiPreview && (
          <>
            <p
              onClick={() => document.getElementById("coi").click()}
              className="cursor-pointer text-primary text-[15px] font-bold leading-loose mt-2 text-center"
            >
              Select New COI
            </p>
          </>
        )}
      </div>

      {/* Contractor License */}
      <div className="flex-1 mt-4">
        <label className="text-blue-900 text-md font-semibold leading-loose">
          Contractor License
        </label>
        <div className="flex flex-col items-center gap-5 mt-2 md:flex-row">
          {contractorLicensePreview ? (
            <>
              <div className="h-40 w-full md:w-40 flex justify-center border-2 shrink-0 border-orange-300 border-dashed rounded-lg overflow-hidden">
                <div className="flex items-center">
                  <span className="ml-1 text-gray-600 text-center">
                    {contractorLicenseFileName}
                  </span>
                </div>
              </div>
              <input
                id="contractorLicense"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setContractorLicense,
                    setContractorLicensePreview,
                    setContractorLicenseFileName
                  )
                }
                accept=".pdf, .docx"
              />
            </>
          ) : (
            <label
              htmlFor="contractorLicense"
              className="flex-1 w-full block h-40 border-2 border-primary bg-[#F4F6FB] border-dashed rounded-lg cursor-pointer p-2"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 mb-3 mt-[-20px] text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <div className="text-center">
                  <span className="text-blue-900 text-[12px] font-bold leading-loose">
                    Drop your License here, or
                  </span>
                  <span className="text-primary text-[15px] font-bold leading-loose ml-2">
                    browse
                  </span>
                </div>
                <p className="text-xs text-center text-gray-500 dark:text-gray-400 mt-1">
                  PDF and docx files are allowed
                </p>
              </div>
              <input
                id="contractorLicense"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setContractorLicense,
                    setContractorLicensePreview,
                    setContractorLicenseFileName
                  )
                }
                accept=".pdf, .docx"
              />
            </label>
          )}
        </div>
        {contractorLicensePreview && (
          <>
            <p
              onClick={() =>
                document.getElementById("contractorLicense").click()
              }
              className="cursor-pointer text-primary text-[15px] font-bold leading-loose mt-2"
            >
              Select New License
            </p>
          </>
        )}
      </div>

      {/* Subcontractor Agreement */}
      <div className="flex-1 mt-4">
        <label className="text-blue-900 text-[14px] font-semibold leading-loose">
          Contractor Agreement
        </label>
        <div className="flex flex-col items-center gap-5 mt-2 md:flex-row">
          {subcontractorAgreementPreview ? (
            <>
              <div className="h-40 w-full md:w-40 flex justify-center border-2 shrink-0 border-orange-300 border-dashed rounded-lg overflow-hidden">
                <div className="flex items-center">
                  <span className="ml-1 text-center text-gray-600">
                    {subcontractorAgreementFileName}
                  </span>
                </div>
              </div>

              <input
                id="subcontractorAgreement"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setSubcontractorAgreement,
                    setSubcontractorAgreementPreview,
                    setSubcontractorAgreementFileName
                  )
                }
                accept=".pdf, .docx"
              />
            </>
          ) : (
            <label
              htmlFor="subcontractorAgreement"
              className="flex-1 w-full block h-40 border-2 border-primary bg-[#F4F6FB] border-dashed rounded-lg cursor-pointer p-2"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 mb-3 mt-[-20px] text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <div className="text-center">
                  <span className="text-blue-900 text-[12px] font-bold leading-loose">
                    Drop your Agreement here, or
                  </span>
                  <span className="text-primary text-[15px] font-bold leading-loose ml-2">
                    browse
                  </span>
                </div>
                <p className="text-xs text-center text-gray-500 dark:text-gray-400 mt-1">
                  PDF and docx files are allowed
                </p>
              </div>
              <input
                id="subcontractorAgreement"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    setSubcontractorAgreement,
                    setSubcontractorAgreementPreview,
                    setSubcontractorAgreementFileName
                  )
                }
                accept=".pdf, .docx"
              />
            </label>
          )}
        </div>
        {subcontractorAgreementPreview && (
          <>
            <p
              onClick={() =>
                document.getElementById("subcontractorAgreement").click()
              }
              className="cursor-pointer text-primary text-[15px] text-center font-bold leading-loose mt-2"
            >
              Select new agreement
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default FileUploadForm;
