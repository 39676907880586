import React from "react";
import { Html } from "@react-three/drei";

const Hotspot = ({ position, label }) => {
  return (
    <mesh position={position}>
      <Html>
        <div className="bg-white p-2 rounded shadow-md w-52">{label}</div>
      </Html>
    </mesh>
  );
};

export default Hotspot;
