import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { z } from "zod";

import usePostQuery from "../../hooks/usePostQuery";
import { useUserSelector } from "../../hooks/useUserSelector";
import Heading from "../../components/layout/Heading";
import ErrorMessage from "../../components/shared/ErrorMessage";
import InputField from "../../components/shared/InputField";
import CustomSelect from "../../components/shared/CustomSelect";
import { queryKeys } from "../../constants";
import { useNavigate } from "react-router-dom";
import useMutate from "../../hooks/useMutate";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetQuery } from "../../hooks/useGetQuery";

const productsSchema = z.object({
  task_id: z.string().nonempty({ message: "Required" }),
  contractor_id: z.string().nonempty({ message: "Required" }),
  labor_hours: z.string().nonempty({ message: "Required" }),
  labor_rate: z.string().nonempty({ message: "Required" }),
  material_unit: z.string().nonempty({ message: "Required" }),
  material_cost_per_unit: z.string().nonempty({ message: "Required" }),
});

const schema = z.object({
  company_id: z.string().min(1, "Company ID is required"),
  client_id: z.string().min(1, "Please select client"),
  project_id: z.string().min(1, "Please select project"),
  budget_name: z.string().min(1, "Budget Name is required"),
  currency: z.string().min(1, "Please select currency"),
  budget_date: z.string().min(1, "Please select budget date"),
  products: z
    .array(productsSchema)
    .min(1, { message: "Please add at least one product" }),
  fixed_cost_amount: z.string().min(1, "Fixed Cost Amount is required"),
  sub_total: z.string().min(1, "Sub Total is required"),
  total: z.string().min(1, "Total is required"),
});

const initialData = {
  task_id: "",
  contractor_id: "",
  labor_hours: "",
  labor_rate: "",
  material_unit: "",
  material_cost_per_unit: "",
  budget: "",
};

function CreateBudget({ budget = null }) {
  const { user } = useUserSelector();
  const [client, setClient] = useState("");
  const [project, setProject] = useState("");
  const [fixedCostAmount, setFixedCostAmount] = useState(
    budget ? budget.fixed_cost_amount : 0
  );

  const [products, setProducts] = useState(
    budget
      ? budget.products.map((prod) => ({
          ...prod,
          fixed_cost_amount: prod.fixed_cost_amount ?? 0,
        }))
      : [initialData]
  );
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      company_id: user.company._id,
      budget_name: budget?.budget_name ?? "",
      client_id: budget?.client._id ?? "",
      project_id: budget?.project_id ?? "",
      budget_date: budget?.budget_date ?? "",
      currency: user.company.company_settings.currency.symbol,
      fixed_cost_amount: budget?.fixed_cost_amount ?? 0,
      sub_total: budget?.sub_total ?? 0,
      total: budget?.total ?? 0,
    },
    //resolver: zodResolver(schema),
  });

  // Log form errors to see if validation is the issue
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const { data: clientsData, isLoading: clientsLoading } = usePostQuery({
    url: "/company/clients",
    queryKey: [queryKeys.Clients],
  });

  const clients = clientsData?.message.map((client) => ({
    id: client._id,
    label: `${client.first_name} ${client.last_name}`,
  }));

  const { data: projectsData, isLoading: projectsLoading } = usePostQuery({
    queryKey: [queryKeys.ProjectsForCompany],
    url: "/projects/for/company",
  });

  const projects = projectsData?.message.map((p) => ({
    id: p._id,
    label: `${p.project_name}`,
  }));

  const { data: contractorsData, isLoading: contractorsLoading } = useGetQuery({
    queryKey: [queryKeys.Builders],
    url: "/my/builders",
    options: {
      headers: {
        companyid: user.user_type === "company" ? user._id : user.company._id,
      },
    },
  });

  const contractors = contractorsData?.message.map((c) => ({
    id: c._id,
    label: `${c.f_name} ${c.l_name}`,
  }));

  const { data: taskData, isLoading: taskLoading } = usePostQuery({
    queryKey: [queryKeys.TasksForCompany],
    url: "/task/for/company",
  });

  const tasks = taskData?.message.map((t) => ({
    id: t._id,
    label: `${t.task_name}`,
  }));

  useEffect(() => {
    if (client) {
      setValue("client_id", client);
      clearErrors("client_id");
    }
  }, [client, setValue, clearErrors]);

  useEffect(() => {
    if (project) {
      setValue("project_id", project);
      clearErrors("project_id");
    }
  }, [project, setValue, clearErrors]);

  const handleChange = (value, index, name) => {
    const updatedProducts = [...products];
    updatedProducts[index] = {
      ...updatedProducts[index],
      [name]:
        name.includes("cost") || name.includes("rate")
          ? parseFloat(value)
          : value,
    };
    setProducts(updatedProducts);
  };

  const remove = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };

  useEffect(() => {
    const subTotal = products.reduce(
      (amt, item) =>
        amt +
        item.labor_hours * item.labor_rate +
        item.material_unit * item.material_cost_per_unit,
      0
    );
    setValue("sub_total", subTotal.toString());
    setSubTotal(subTotal);

    const total =
      subTotal +
      products.reduce((total, item) => total + item.fixed_cost_amount, 0);
    setValue("total", total.toString());
    setTotal(total);

    const productIds = products.map((product) => ({
      task_id: product.task_id,
      contractor_id: product.contractor_id,
      labor_hours: product.labor_hours,
      labor_rate: product.labor_rate,
      material_unit: product.material_unit,
      material_cost_per_unit: product.material_cost_per_unit,
      budget:
        product.labor_hours * product.labor_rate +
        product.material_unit * product.material_cost_per_unit,
      fixed_cost_amount: product.fixed_cost_amount,
    }));
    setValue("products", productIds);

    if (subTotal !== 0) {
      clearErrors("products");
    }
  }, [products, setValue, clearErrors]);

  useEffect(() => {
    if (budget) {
      setProducts(budget.products);
      setFixedCostAmount(budget.fixed_cost_amount);
      setSubTotal(budget.sub_total);
      setTotal(budget.total);
    }
  }, [budget]);

  const { mutate } = useMutate([queryKeys.CreateInvoice]);
  const submitData = (data) => {
    console.log(data);
    const toastId = toast.loading("Adding Budget...");

    if (budget) {
      data.budget_id = budget._id;
    }

    mutate(
      {
        url: `/budget/new`,
        data,
        method: "POST",
      },
      {
        onSuccess(data) {
          toast.dismiss(toastId);
          toast.success("Budget added successfully");
          navigate(`/payment/budget`);
        },
        onError(error) {
          toast.dismiss(toastId);
          toast.error(error.response.data.message);
        },
      }
    );
  };

  return (
    <div className="px-12 relative">
      <div className="mb-4">
        <Heading label={"Add A Budget"} />
      </div>

      <form
        onSubmit={handleSubmit(submitData)}
        className="bg-white px-10 py-5 rounded-md"
      >
        <div className="form-row">
          <InputField
            label="Budget Name"
            name="budget_name"
            register={register}
            error={errors.budget_name?.message}
            required
          />
          <div className="flex-1 mx-2">
            <CustomSelect
              data={clients}
              label="Select Client"
              onChange={setClient}
              loading={clientsLoading}
              link="/clients/add-client"
              initialValue={budget?.client._id}
            />
            <ErrorMessage errors={errors} name="client_id" />
          </div>
          <div className="flex-1 mx-2">
            <CustomSelect
              data={projects}
              label="Select Project"
              onChange={setProject}
              loading={projectsLoading}
              link="/projects/add-project"
              initialValue={budget?.project._id}
            />
            <ErrorMessage errors={errors} name="project_id" />
          </div>
          <div className="flex-1 mx-2">
            <InputField
              type="date"
              label="Budget Date"
              name="budget_date"
              register={register}
              error={errors.budget_date?.message}
              required
            />
          </div>
        </div>

        <div className="border-b mt-4 mb-4"></div>

        <div className="w-full border mt-4 rounded-md p-3 bg-white">
          <div className="flex w-full text-black text-sm font-bold leading-tight mb-2">
            <div className="w-[21%] mx-2">
              <div className="ml-1">Task</div>
            </div>
            <div className="w-[20%] mx-2">Contractor(s) / Vendor(s)</div>
            <div className="w-[8%] mx-2">Labor-HRS</div>
            <div className="w-[10%] mx-2">Labor-RATE</div>
            <div className="w-[8%] mx-2">Material-UNITS</div>
            <div className="w-[10%] mx-2">Material-CPU</div>
            <div className="w-[15%] mx-2">Budget</div>
            <div className="w-[8%] text-center">Action</div>
          </div>
          {products.map((item, index) => (
            <>
              <div
                className="flex w-full text-sm font-semibold leading-tight mt-3"
                key={index}
              >
                <div className="w-[21%] mx-2 mb-4">
                  <CustomSelect
                    data={tasks}
                    onChange={(value) => handleChange(value, index, "task_id")}
                    loading={taskLoading}
                    initialValue={item.task_id}
                  />
                  {errors.products?.[index]?.task_id && (
                    <ErrorMessage
                      errors={errors}
                      name={`products.${index}.task_id`}
                    />
                  )}
                </div>

                <div className="w-[20%] mx-2 mb-4">
                  <CustomSelect
                    data={contractors}
                    onChange={(value) =>
                      handleChange(value, index, "contractor_id")
                    }
                    loading={contractorsLoading}
                    initialValue={item.contractor_id}
                  />
                  {errors.products?.[index]?.contractor_id && (
                    <ErrorMessage
                      errors={errors}
                      name={`products.${index}.contractor_id`}
                    />
                  )}
                </div>

                <div className="w-[8%] mx-2 mb-4">
                  <InputField
                    type="number"
                    placeholder="HRS"
                    name={`products[${index}].labor_hours`}
                    value={item.labor_hours}
                    onChange={(e) =>
                      handleChange(e.target.value, index, "labor_hours")
                    }
                    error={errors.products?.[index]?.labor_hours?.message}
                  />
                </div>

                <div className="w-[10%] mx-2 mb-4">
                  <InputField
                    type="number"
                    placeholder="RATE"
                    name={`products[${index}].labor_rate`}
                    value={item.labor_rate}
                    onChange={(e) =>
                      handleChange(e.target.value, index, "labor_rate")
                    }
                    error={errors.products?.[index]?.labor_rate?.message}
                  />
                </div>

                <div className="w-[8%] mx-2 mb-4">
                  <InputField
                    type="number"
                    placeholder="UNITS"
                    name={`products[${index}].material_unit`}
                    value={item.material_unit}
                    onChange={(e) =>
                      handleChange(e.target.value, index, "material_unit")
                    }
                    error={errors.products?.[index]?.material_unit?.message}
                  />
                </div>

                <div className="w-[10%] mx-2 mb-4">
                  <InputField
                    type="number"
                    placeholder="CPU"
                    name={`products[${index}].material_cost_per_unit`}
                    value={item.material_cost_per_unit}
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        index,
                        "material_cost_per_unit"
                      )
                    }
                    error={
                      errors.products?.[index]?.material_cost_per_unit?.message
                    }
                  />
                </div>

                <div className="w-[15%] mx-2 mb-4">
                  <InputField
                    type="number"
                    placeholder="BUDGET"
                    name={`products[${index}].budget`}
                    value={
                      item.labor_hours * item.labor_rate +
                      item.material_unit * item.material_cost_per_unit
                    }
                    disabled
                  />
                </div>

                <div className="w-[8%] text-center">
                  <button
                    type="button"
                    className="mx-auto"
                    onClick={() => remove(index)}
                  >
                    <Icon
                      icon="material-symbols:delete-outline-rounded"
                      className="text-gray-400 text-2xl"
                    />
                  </button>
                </div>
              </div>

              <div className="w-[20%] mt-[-10px] mb-0 pl-2">
                <InputField
                  placeholder="Fixed Cost Amount"
                  type="number"
                  name={`products[${index}].fixed_cost_amount`}
                  value={item.fixed_cost_amount}
                  onChange={(e) => {
                    handleChange(e.target.value, index, "fixed_cost_amount");
                    clearErrors(`products.${index}.fixed_cost_amount`);
                  }}
                  error={errors.products?.[index]?.fixed_cost_amount?.message}
                  required={false}
                />
              </div>
            </>
          ))}

          <div className="flex justify-between items-center mt-4 pl-2">
            <button
              type="button"
              onClick={() => setProducts([...products, initialData])}
              className="border rounded bg-primary text-white px-3 py-1 text-xs h-[40px] w-[120px]"
            >
              Add Product
            </button>
          </div>
        </div>

        <div className="mt-6 w-full flex justify-end">
          <div className="flex w-[45%]">
            <div className="flex-1 flex flex-col space-y-3">
              <div className="flex-1 flex justify-between">
                <span className="font-semibold">Subtotal</span>
                <span>{subTotal}</span>
              </div>
              <div className="flex-1 flex justify-between">
                <span className="font-semibold">Total</span>
                <span>{total}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 w-full flex justify-end">
          <button
            type="submit"
            className="px-6 py-2 text-white bg-primary rounded"
          >
            Save Budget
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateBudget;
