import React, { useRef, useEffect, useState } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import {
  OrthographicCamera,
  OrbitControls,
  DragControls,
} from "@react-three/drei";
import * as THREE from "three";

const FloorPlan = ({ model }) => {
  const { scene, gl, camera } = useThree();
  const cameraRef = useRef();
  const [draggableObjects, setDraggableObjects] = useState([]);

  useEffect(() => {
    if (model) {
      // Set model material to be transparent and walls to green
      const newDraggableObjects = [];
      model.traverse((child) => {
        if (child.isMesh) {
          if (child.name.includes("Wall")) {
            child.material.color.set("green"); // Set walls to green
          }
          child.material.transparent = true;
          child.material.opacity = 0.3; // Set desired transparency level
          newDraggableObjects.push(child); // Add each child to draggable objects
        }
      });

      // Add the model to the scene
      scene.add(model);

      // Set draggable objects
      setDraggableObjects(newDraggableObjects);
    }

    return () => {
      // Clean up the scene when the component unmounts
      if (model) {
        scene.remove(model);
      }
    };
  }, [model, scene]);

  useFrame(() => {
    if (cameraRef.current) {
      // Adjust camera position to top-down view
      cameraRef.current.position.set(0, 10, 0);
      cameraRef.current.lookAt(0, 0, 0);
    }
  });

  return (
    <>
      <OrthographicCamera
        ref={cameraRef}
        makeDefault
        zoom={50}
        position={[0, 10, 0]}
      />
      <OrbitControls enableZoom enablePan={true} enableRotate={true} />
      <DragControls
        transformGroup={true}
        args={[draggableObjects, camera, gl.domElement]}
      />
    </>
  );
};

export default FloorPlan;
