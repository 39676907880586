import React, { useEffect, useState } from "react";
import { useLoader, extend, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { Text } from "@react-three/drei";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

extend({ OrbitControls });

function Model({ setWalls, setModel, fileUrl }) {
  const gltf = useLoader(GLTFLoader, fileUrl);
  const [wallDimensions, setWallDimensions] = useState([]);
  const [floorDimensions, setFloorDimensions] = useState(null);
  const { camera, gl } = useThree();
  const controlsRef = React.useRef();

  // Function to generate a random color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Convert meters to feet
  const metersToFeet = (meters) => (meters * 3.28084).toFixed(2);
  // Convert square meters to square feet
  const squareMetersToSquareFeet = (sqMeters) =>
    (sqMeters * 10.7639).toFixed(2);

  useEffect(() => {
    if (gltf && gltf.scene) {
      const identifyObject = (object, names) => {
        const objectName = object.name;
        return names.find((name) => objectName.startsWith(name));
      };

      const sceneObjects = gltf.scene.children;
      const targetNames = [
        "Floor",
        "Wall",
        "Fireplace",
        "Sofa",
        "Storage",
        "Table",
        "Stove",
        "Television",
        "livingRoom",
        "Chair",
        "kitchen",
        "Section",
        "Refrigerator",
        "Oven",
        "Dishwasher",
        "Sink",
      ];

      const colorMap = {
        Floor: new THREE.Color("#FFFFFF"), // White for floors
        Television: new THREE.Color("#000000"), // Black for televisions
        Sink: new THREE.Color("#FFFFFF"),
        Storage: new THREE.Color("#92400e"),
        Wall: new THREE.Color("#a1a1aa"),
        Table: new THREE.Color("#1e293b"),
        Chair: new THREE.Color("#facc15"),
        Refrigerator: new THREE.Color("#bfdbfe"),
        Oven: new THREE.Color("#e9d5ff"),
        Fireplace: new THREE.Color("#ef4444"),
        Sofa: new THREE.Color("#2dd4bf"),
      };

      targetNames.forEach((name) => {
        if (!colorMap[name]) {
          colorMap[name] = new THREE.Color(getRandomColor());
        }
      });

      const wallObjects = [];
      const wallDims = [];
      let floorDims = null;

      const traverseAndIdentify = (object) => {
        const targetName = identifyObject(object, targetNames);
        if (targetName) {
          if (object.isMesh) {
            wallObjects.push(object);

            if (
              targetName === "Wall" ||
              targetName === "Floor" ||
              targetName === "Storage" ||
              targetName === "Television" ||
              targetName === "Sink" ||
              targetName === "Table" ||
              targetName === "Chair" ||
              targetName === "Refrigerator" ||
              targetName === "Oven"
            ) {
              // Calculate dimensions
              const boundingBox = new THREE.Box3().setFromObject(object);
              const size = boundingBox.getSize(new THREE.Vector3());
              const dimensions = {
                name: object.name,
                width: metersToFeet(size.x),
                height: metersToFeet(size.y),
                position: boundingBox.getCenter(new THREE.Vector3()),
              };

              if (targetName === "Wall") {
                wallDims.push(dimensions);
              } else if (targetName === "Floor") {
                floorDims = {
                  ...dimensions,
                  area: squareMetersToSquareFeet(size.x * size.z), // Calculate area for the floor
                };
              }

              object.material = new THREE.MeshStandardMaterial({
                color: colorMap[targetName],
                metalness: 0.5, // Add metallic shine
                roughness: 0.1, // Low roughness for shiny surface
                wireframe: false,
                shadowSide: THREE.FrontSide,
                precision: "highp",
                vertexColors: false,
                format: 1033,
                clipShadows: true,
              });
              object.castShadow = true; // Enable casting shadows
              object.receiveShadow = true; // Enable receiving shadows
            }
          }
        }
        if (object.children && object.children.length > 0) {
          object.children.forEach((child) => {
            traverseAndIdentify(child);
          });
        }
      };

      sceneObjects.forEach((object) => traverseAndIdentify(object));

      setWalls(wallObjects);
      setModel(gltf.scene); // Set the model state with the loaded scene
      setWallDimensions(wallDims); // Set wall dimensions state
      setFloorDimensions(floorDims); // Set floor dimensions state
    }
  }, [gltf, setWalls, setModel]);

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.addEventListener("start", () => {
        console.log("start");
        gl.domElement.style.cursor = "grab";
      });
      controlsRef.current.addEventListener("end", () => {
        console.log("end");
        gl.domElement.style.cursor = "pointer";
      });
    }
  }, [gl]);

  if (!gltf) {
    return null;
  }

  return (
    <>
      <primitive object={gltf.scene} />
      <orbitControls
        ref={controlsRef}
        args={[camera, gl.domElement]}
        enablePan={true}
        enableRotate={true}
        enableZoom={true}
      />
      {wallDimensions.map((dim, index) => (
        <Text
          key={index}
          position={[
            dim.position.x,
            dim.position.y + parseFloat(dim.height) / 6 + 0.0, // Adjust the y position to be on top of the object
            dim.position.z,
          ]}
          fontSize={0.2}
          color={getRandomColor()}
          anchorX="center"
          anchorY="top-baseline"
        >
          {`${dim.name}: ${dim.width}ft x ${dim.height}ft`}
        </Text>
      ))}
      {floorDimensions && (
        <Text
          position={[
            floorDimensions.position.x,
            floorDimensions.position.y + 2.0, // Adjust the y position to be slightly above the floor
            floorDimensions.position.z,
          ]}
          fontSize={0.2}
          color="white"
          anchorX="center"
          anchorY="top-baseline"
        >
          {`${floorDimensions.name}: ${floorDimensions.area}ft²`}
        </Text>
      )}
    </>
  );
}

export default Model;
