import React, { useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import Heading from "../../components/layout/Heading";
import useFormatCurrency from "../../hooks/useFormatCurrency";

const BudgetDetails = ({ budget, setBudget }) => {
  const check_budget = budget?.products ?? [];
  console.log("budget:::", check_budget);
  const { formatCurrency } = useFormatCurrency();

  const mapped = check_budget.map((b) => {
    const cleaned = {
      task_group: `${b.task_group}`.toUpperCase(),
      task: b.task_name,
      vendor: b.contractor_name,
      hrs: b.labor_hours,
      rate: b.labor_rate,
      units: b.material_unit,
      unitPrice: b.material_cost_per_unit,
      fixedCost: b.fixed_cost_amount,
      budget: b.budget,
      actual: b.actual,
      variance: b.variance,
    };
    return cleaned;
  });
  const data = useMemo(() => mapped);

  const columns = useMemo(
    () => [
      {
        accessorKey: "task",
        id: "task",
        header: "",
        columns: [
          {
            accessorKey: "task_group",
            id: "task_group",
            header: "TASK ",
          },
        ],
      },
      {
        accessorKey: "vendor",
        id: "vendor_header",
        header: "",
        columns: [
          {
            accessorKey: "vendor",
            id: "vendor",
            header: "VENDOR(S) / CONTRACTOR(S)",
          },
        ],
      },
      {
        id: "labor",
        header: "LABOR",
        columns: [
          {
            accessorKey: "hrs",
            id: "hrs",
            header: "HRS",
          },
          {
            accessorKey: "rate",
            id: "rate",
            header: `RATE ${
              budget?.currency !== undefined ? budget?.currency : ""
            }`,
          },
        ],
      },
      {
        id: "materials",
        header: "MATERIALS",
        columns: [
          {
            accessorKey: "units",
            id: "units",
            header: "UNITS",
          },
          {
            accessorKey: "unitPrice",
            id: "unitPrice",
            header: "$ / UNIT",
          },
        ],
      },
      {
        id: "fixedCost",
        header: "FIXED COST",
        columns: [
          {
            accessorKey: "fixedCost",
            id: "fixedCostAmount",
            header: `AMOUNT ${
              budget?.currency !== undefined ? budget?.currency : ""
            }`,
          },
        ],
      },
      {
        id: "financial",
        header: "",
        columns: [
          {
            accessorKey: "budget",
            id: "budget",
            header: `BUDGET ${
              budget?.currency !== undefined ? budget?.currency : ""
            }`,
          },
          {
            accessorKey: "actual",
            id: "actual",
            header: `ACTUAL ${
              budget?.currency !== undefined ? budget?.currency : ""
            }`,
          },
          {
            accessorKey: "variance",
            id: "variance",
            header: `VARIANCE ${
              budget?.currency !== undefined ? budget?.currency : ""
            }`,
            cell: (props) => (
              <span className={props.getValue() < 0 ? "text-red-500" : ""}>
                {props.getValue()}
              </span>
            ),
          },
        ],
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const groupedData = data.reduce((acc, row) => {
    (acc[row.task_group] = acc[row.task_group] || []).push(row);
    return acc;
  }, {});

  const calculateSum = (key) =>
    data.reduce((sum, row) => sum + (row[key] || 0), 0);

  const totalBudget = calculateSum("budget");
  const totalActual = calculateSum("actual");
  const totalVariance = calculateSum("variance");

  return (
    <div className="pl-0 pr-0 mt-5 cursor-pointer">
      <div className="flex justify-between items-center mb-8">
        <div>
          <Heading label="Budget Details" />
        </div>
      </div>

      <div className="p-0">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`px-4 py-2 border-b border-gray-300 text-left ${
                      header.column.id === "labor" ||
                      header.column.id === "hrs" ||
                      header.column.id === "rate"
                        ? "bg-yellow-100"
                        : header.column.id === "materials" ||
                          header.column.id === "units" ||
                          header.column.id === "unitPrice"
                        ? "bg-gray-100"
                        : header.column.id === "fixedCost" ||
                          header.column.id === "fixedCostAmount"
                        ? "bg-gray-200"
                        : header.column.id === "vendor"
                        ? "bg-blue-200"
                        : ""
                    }`}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {Object.keys(groupedData).map((taskGroup, groupIndex) => (
              <React.Fragment key={groupIndex}>
                <tr>
                  <td
                    colSpan={10}
                    className="bg-gray-100 px-4 py-2 border-b border-gray-300"
                  >
                    {taskGroup}
                  </td>
                </tr>
                {groupedData[taskGroup].map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.keys(row).map(
                      (key) =>
                        key !== "task_group" && (
                          <td
                            key={key}
                            className={`px-4 py-2 border-b border-gray-300 ${
                              key === "hrs" || key === "rate"
                                ? "bg-yellow-100"
                                : key === "units" || key === "unitPrice"
                                ? "bg-gray-100"
                                : key === "fixedCost" ||
                                  key === "fixedCostAmount"
                                ? "bg-gray-200"
                                : key === "vendor"
                                ? "bg-blue-200"
                                : ""
                            }`}
                          >
                            {key === "variance" ? (
                              <span
                                className={row[key] < 0 ? "text-red-500" : ""}
                              >
                                {row[key]}
                              </span>
                            ) : (
                              row[key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </React.Fragment>
            ))}
            <tr>
              <td
                colSpan={7}
                className="px-4 py-2 border-b border-gray-300 text-left"
              >
                TOTAL
              </td>
              <td className="px-4 py-2 border-b border-gray-300 text-left">
                {formatCurrency(totalBudget)}
              </td>
              <td className="px-4 py-2 border-b border-gray-300 text-left">
                {formatCurrency(totalActual)}
              </td>
              <td className="px-4 py-2 border-b border-gray-300 text-left">
                <span
                  className={
                    totalVariance < 0 ? "text-red-500" : "text-green-500"
                  }
                >
                  {formatCurrency(totalVariance)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BudgetDetails;
